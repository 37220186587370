<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { getAction } from '@/command/netTool'
export default {
  name: 'settlementAudit',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/jsOrder/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          type: 'input',
          key: 'shopName',
        },
        {
          type: 'text',
          label: '',
        },
      ]
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'shopId',
          title: 'ID',
          align: 'left',
          isId: true,
          sorter: (a, b) => a.shopId - b.shopId,
        },
        {
          dataIndex: 'shopName',
          title: '店铺',
          align: 'left',
          sorter: (a, b) => a.shopName.length - b.shopName.length,
        },
        {
          dataIndex: 'orderNum',
          title: '订单量',
          align: 'left',
          sorter: (a, b) => a.orderNum - b.orderNum,
        },
        {
          dataIndex: 'totalAmount',
          title: '销售总额',
          align: 'left',
          sorter: (a, b) => a.totalAmount - b.totalAmount,
          customRender: (text) => {
            return `￥${text}`
          },
          onExport: (text) => {
            return `￥${text}`
          },
        },
        {
          dataIndex: 'noJsAmount',
          title: '不结算金额',
          align: 'left',
          sorter: (a, b) => a.noJsAmount - b.noJsAmount,
          customRender: (text) => {
            return `￥${text}`
          },
          onExport: (text) => {
            return `￥${text}`
          },
        },
        {
          dataIndex: 'jsAmount',
          title: '已结算金额',
          align: 'left',
          sorter: (a, b) => a.jsAmount - b.jsAmount,
          customRender: (text) => {
            return `￥${text}`
          },
          onExport: (text) => {
            return `￥${text}`
          },
        },
        {
          dataIndex: 'unJsAmount',
          title: '未结算金额',
          align: 'left',
          sorter: (a, b) => a.unJsAmount - b.unJsAmount,
          customRender: (text) => {
            return `￥${text}`
          },
          onExport: (text) => {
            return `￥${text}`
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '结算',
                onClick: () => {
                  this.$router.push(`/audit/settlementAuditIndexDetail?shopId=${records.shopId}`)
                },
              },
            ] 
          },
        },
      ]
    },
    getButton() {
      return [
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //         // let idList = data.map(e => e.id)
        //         // api.command.edit.call(this, {
        //         //   url: '/base/farmEvaluate/deleteBatch',
        //         //   params: { idList }
        //         // })
        //       }
        //     }
        //   ]
        // }
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
